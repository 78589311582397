<template>
  <div :class="$style.details">
    <div :class="$style.tableContainer">
      <a-table
        :columns="columns"
        :scroll="{ x: 1300, y: 450 }"
        style="height: 400px"
        :row-key="record => record.pkId"
        :loading="loading"
        :dataSource="tableList"
        :pagination="pagination"
        @change="handlePagination"
      >
        <template slot="position" slot-scope="text, record">
          <span
            :class="$style.positionCell"
            @click="handleShowMap(record)"
            v-if="record.lat || record.lng"
            >{{ `${record.lng},${record.lat}` }}</span
          >
          <span v-else>无</span>
        </template>
        <template slot="eventDetail" slot-scope="record">
          <div v-html="record.content" :class="$style.eventDetail"></div>
        </template>
        <!-- <template slot="user" slot-scope="text, record">
          <div :class="$style.userCell">
            <a-tooltip>
              <template slot="title">
                <div>姓名：{{ record.user }}</div>
                <div style="cursor: pointer;" @click="copyInfo(record.user)">
                  电话：1001001
                </div>
              </template>
              <span>{{ text }}</span>
            </a-tooltip>
          </div>
        </template> -->
      </a-table>
    </div>
  </div>
</template>

<script>
import {
  Component,
  Vue,
  Inject,
  InjectReactive,
  Prop,
} from 'vue-property-decorator';
// import { clipboardWrite } from '@triascloud/utils';
import { getEventList } from '@/services/smart-hat/device-management';
import { crossStorageModule } from '@/enum/store.js';
import { checkHasShare } from '@/views/hat/screen/utils';
import { getEventList as shareData } from '@/services/smart-hat/screen-share';

@Component
export default class Details extends Vue {
  @InjectReactive('deviceDetail') deviceDetail;
  @Inject('CHANGE_MINI_MAP') changeMapStatus;
  @crossStorageModule.State locale;

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: this.$t('hat.clockRecord.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('hat.managementModal.event.time'),
        width: 180,
        dataIndex: 'messageTime',
        customRender: text => this.$moment(text).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        align: 'left',
        title: this.$t('hat.managementModal.event.eventType'),
        dataIndex: 'subType',
        width: 140,
        customRender: text => this.exchangeValue(text),
      },
      {
        align: 'left',
        title: this.$t('hat.managementModal.event.position'),
        width: 200,
        scopedSlots: { customRender: 'position' },
      },
      {
        align: 'left',
        title: this.$t('hat.broadcast.user'),
        dataIndex: 'userName',
        width: 120,
      },
      {
        align: 'left',
        title: this.$t('hat.managementModal.event.eventDetail'),
        scopedSlots: { customRender: 'eventDetail' },
      },
    ];
  }
  pagination = {
    showQuickJumper: true,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '30', '40'],
    pageSize: 5,
    current: 1,
    total: 0,
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  mounted() {
    this.fetchData();
  }
  tableList = [];
  loading = false;
  async fetchData(params = {}, pagination = false) {
    if (pagination) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
    }
    this.loading = true;
    let data = {
      deviceId: this.deviceDetail.deviceId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (params.user) {
      data.userId = params.user;
    }
    if (params.event) {
      data.subType = params.event;
    }
    if (params.timeRange && params.timeRange.length > 0) {
      data.startTime = this.$moment(params.timeRange[0]).valueOf();
      data.endTime = this.$moment(params.timeRange[1]).valueOf();
    }
    try {
      const { records, total } = !checkHasShare(this.$route)
        ? await getEventList(data)
        : await shareData({
            ...data,
            shareId: this.$route.params.id,
            password: this.$route.params.password,
          });
      this.pagination.total = total;
      this.tableList = records;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.fetchData();
  }
  handleShowMap(record) {
    this.changeMapStatus(true, [record.lng, record.lat]);
  }
  // copyInfo(text) {
  //   clipboardWrite(text).then(() => {
  //     this.$message.success('手机号码复制成功');
  //   });
  // }
  @Prop() eventList;
  exchangeValue(value) {
    const idx = this.eventList.findIndex(v => v.id === value);
    return this.locale === 'en_US'
      ? this.eventList[idx]?.id
      : this.eventList[idx]?.name || '';
  }
}
</script>

<style lang="less" module>
.details {
  white-space: nowrap;
  .tableContainer {
    margin-top: 8px;
    .eventDetail {
      a {
        display: block;
      }
    }
    .positionCell {
      cursor: pointer;
    }
    .positionCell:hover {
      color: var(--primary);
    }
    .userCell {
      display: flex;
      align-items: center;
      color: var(--primary);
    }
  }
  .btn {
    margin-right: 10px;
    min-width: 50px;
    height: 31px;
  }
}
</style>
