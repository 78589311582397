<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { audioConvert, getAudioConvertResult } from '@/services/oss/oss';
import { getRecognitionContent } from '@/services/smart-hat/file';
import { UploadService } from '@triascloud/services';
import {
  getAuthShare,
  getRecognition,
  audioConvert as audioConvertShare,
  audioConvertStatus,
} from '@/services/smart-hat/screen-share';
import { checkHasShare } from '@/views/hat/screen/utils';
import router from '@/router';

@Component()
export default class PreviewAudio extends Vue {
  @Prop({ type: String, required: true }) url;
  @Prop({ type: String }) fileId;

  mounted() {
    this.fileId && this.getSubtitles();
  }

  @Watch('url', { immediate: true })
  async pathChange(path) {
    if (path) {
      await this.$nextTick();
      await this.getAbsolutePath();
    }
  }

  absolutePath = '';
  async getAbsolutePath() {
    try {
      const ext = this.getExt(this.url);
      if (['mp3'].includes(ext)) {
        // 判断是否为绝对路径
        if (this.url.includes('http')) {
          this.$refs.audio.src = this.url;
        } else {
          // 判断是否为分享页
          if (!checkHasShare(router.app.$route)) {
            const customService = new UploadService('/oss/iot/oss');
            this.absolutePath = await customService.getAuth(this.url);
            this.$refs.audio.src = this.absolutePath;
          } else {
            const data = await getAuthShare({
              url: this.url instanceof Array ? this.url : [this.url],
              shareId: router.app.$route.params.id,
              password: router.app.$route.params.password,
            });
            this.absolutePath = Object.values(data)[0];
            this.$refs.audio.src = this.absolutePath;
          }
        }
      } else {
        // 判断是否为分享页
        if (!checkHasShare(router.app.$route)) {
          const path = this.url.includes('http')
            ? this.url.match(/com\/(\S*)\?/)[1]
            : this.url;
          const customService = new UploadService('/oss/iot/oss');
          // 查找oss音频文件的MP3格式地址
          this.absolutePath = await customService.getAuth(
            path.replace('.amr', '.mp3'),
          );
          this.$refs.audio.src = this.absolutePath;
        } else {
          const path = this.url.includes('http')
            ? this.url.match(/com\/(\S*)\?/)[1]
            : this.url;
          const data = await getAuthShare({
            url:
              path instanceof Array
                ? path.replace('.amr', '.mp3')
                : [path.replace('.amr', '.mp3')],
            shareId: router.app.$route.params.id,
            password: router.app.$route.params.password,
          });
          this.absolutePath = Object.values(data)[0];
          this.$refs.audio.src = this.absolutePath;
        }
      }
    } catch {
      return false;
    }
  }

  time = 0; // 加载次数
  // 音频加载失败的回调(oss如果不存在已经转换过的音频文件就会执行以下方法)
  async handleAudioError() {
    if (this.time > 0) {
      return;
    }
    const path = this.url.match(/com\/(\S*)\?/)[1];
    const params = {
      format: 'MP3',
      sourcePath: path,
      targetPath: path.replace('.amr', '.mp3'),
    };
    if (!checkHasShare(router.app.$route)) {
      const taskId = await audioConvert(params);
      await getAudioConvertResult(taskId);
    } else {
      const taskId = await audioConvertShare({
        ...params,
        shareId: this.$route.params.id,
        password: this.$route.params.password,
      });
      await audioConvertStatus(
        {
          shareId: this.$route.params.id,
          password: this.$route.params.password,
        },
        taskId,
      );
    }
    // 判断是否为分享页
    if (!checkHasShare(router.app.$route)) {
      const customService = new UploadService('/oss/iot/oss');
      this.absolutePath = await customService.getAuth(params.targetPath);
    } else {
      const data = await getAuthShare({
        url:
          params.targetPath instanceof Array
            ? params.targetPath
            : [params.targetPath],
        shareId: router.app.$route.params.id,
        password: router.app.$route.params.password,
      });
      this.absolutePath = Object.values(data)[0];
    }
    setTimeout(() => {
      this.$refs.audio.src = this.absolutePath;
    }, 1000);
    this.time++;
  }

  getExt = url => {
    const result = /\.([^.]+)$/.exec(url.split('?')[0]);
    return result ? result[1].toLowerCase() : null;
  };

  @Watch('subtitles')
  subtitlesChange() {
    let subtitlesContent = this.subtitles;
    this.highlightTextList.forEach(item => {
      const regExp = new RegExp(item, 'g');
      subtitlesContent = subtitlesContent.replace(
        regExp,
        `<span class="audio-mark-text">${item}</span>`,
      );
    });
    document.getElementById('subtitles').innerHTML = subtitlesContent;
  }

  subtitles = '';
  timeUpdate(e) {
    const currentTime = e.target.currentTime * 1000;
    this.subtitleList.forEach(item => {
      if (currentTime > item.BeginTime && currentTime < item.EndTime) {
        this.subtitles = item.Text;
      }
    });
  }

  subtitleList = [];
  highlightTextList = [];
  async getSubtitles() {
    const data = !checkHasShare(router.app.$route)
      ? await getRecognitionContent(this.fileId)
      : await getRecognition({
          fileId: this.fileId,
          shareId: this.$route.params.id,
          password: this.$route.params.password,
        });
    if (data?.caption) {
      const { caption, highlight } = data;
      this.subtitleList = JSON.parse(caption).Result.Sentences;
      this.highlightTextList = JSON.parse(highlight).Words.map(
        item => item.Word,
      );
      this.highlightTextList = Array.from(new Set(this.highlightTextList));
    }
  }

  render() {
    return (
      <div class="x-preview-audio">
        <div class="subtitles" id="subtitles"></div>
        <audio
          controls
          ref="audio"
          class="audio"
          onTimeupdate={this.timeUpdate}
          onError={this.handleAudioError}
        >
          <source type="audio/mpeg" />
        </audio>
      </div>
    );
  }
}
</script>
<style lang="less" scoped>
.x-preview-audio {
  // width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .audio {
    width: 500px;
  }
  .subtitles {
    height: 48px;
    font-size: 24px;
    color: #fff;
    text-align: center;
  }
}
</style>
<style>
.audio-mark-text {
  background: var(--primary);
  display: inline-block;
  padding: 0 4px;
  margin: 0 2px;
  border-radius: 4px;
  text-indent: 0px;
}
</style>
