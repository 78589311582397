<template>
  <div :class="$style.form">
    <div :class="$style.tabList">
      <div
        :class="[$style.tab, activeIndex === index ? $style.active : '']"
        v-for="(item, index) in tabList"
        :key="item.value"
        @click="handleTabChange(index, item.value)"
      >
        {{ item.name }}
      </div>
      <a-range-picker
        v-model="timeRange"
        :ranges="presetTime"
        style="width: 380px; margin-left: 10px"
        :show-time="{ format: 'YYYY-MM-DD HH:mm:ss' }"
        format="YYYY-MM-DD HH:mm:ss"
        :disabled-date="disabledDate"
      />
    </div>
    <div :class="$style.topSelects">
      <a-select
        :class="$style.select"
        v-model="searchForm.cloudStatus"
        :placeholder="$t('hat.sos.columns.allStates')"
        allow-clear
      >
        <a-select-option v-for="item in statusList" :key="item" :value="item">
          {{ CLOUD_STATUS_ENUM[item] }}
        </a-select-option>
      </a-select>
      <a-select
        :class="$style.select"
        v-model="searchForm.type"
        :placeholder="$t('hat.managementModal.file.allType')"
        allow-clear
      >
        <a-select-option v-for="item in fileTypeList" :key="item" :value="item">
          {{ FILE_TYPE[item] }}
        </a-select-option>
      </a-select>
      <a-select
        :class="$style.select"
        v-model="searchForm.userId"
        :placeholder="$t('hat.managementModal.event.member')"
        allow-clear
      >
        <a-select-option
          v-for="item in userList"
          :key="item.userId"
          :value="item.userId"
        >
          {{ item.userName }}
        </a-select-option>
      </a-select>
      <a-select
        :class="$style.select"
        v-model="searchForm.sorter"
        :placeholder="$t('hat.managementModal.file.timeDescending')"
      >
        <a-select-option v-for="item in sorterList" :key="item" :value="item">
          {{ SORTER_ENUM[item] }}
        </a-select-option>
      </a-select>
      <a-input
        style="width: 150px; margin-right: 10px"
        v-model="searchForm.content"
        :placeholder="$t('hat.managementModal.file.md5Remark')"
      />
    </div>
    <div :class="$style.total">
      <span
        >{{ $t('hat.managementModal.file.synchronized') }}：{{
          totalCount.syncCount.number
        }}个/{{ totalCount.syncCount.size }}M</span
      >
      <span style="margin-left: 25px"
        >{{ $t('hat.managementModal.file.toBeSynchronized') }}：{{
          totalCount.unSyncCount.number
        }}个/{{ totalCount.unSyncCount.size }}M</span
      >
    </div>
    <div :class="$style.buttons">
      <a-button type="primary" :class="$style.btn" @click="handleSearch">
        {{ $t('hat.managementModal.event.search') }}
      </a-button>
      <a-button :class="$style.btn" @click="handleReset">
        {{ $t('msg.reset') }}
      </a-button>
    </div>
    <a-spin :spinning="loading">
      <empty-content
        :style="{ height: '300px', width: '100%' }"
        label=""
        v-if="!fileList.length"
      />
      <x-scroll-view
        :class="$style.fileList"
        :disabled="isEnd"
        :lowerThreshold="80"
        :immediateCheck="false"
        @scrolltolower="loadFileList(false)"
      >
        <div
          :class="$style.file"
          v-for="(item, index) in fileList"
          :key="index"
        >
          <div :class="$style.top">
            <div :class="$style.userAvatar" :title="item.userName">
              <x-oss-image
                :class="$style.avatar"
                v-if="item.userAvatar"
                :ossPath="item.userAvatar"
              />
              <span :class="[$style.avatar, $style.noAvatar]" v-else>{{
                item.userName.charAt(0)
              }}</span>
            </div>
            <div :class="$style.time">
              {{ $moment(item.fileTime).format('YYYY-MM-DD HH:mm:ss') }}
            </div>
            <a-icon
              type="check-circle"
              theme="filled"
              :class="$style.synchronize"
              v-if="item.cloudStatus === 'SYNC'"
              style="color: #52c774"
            />
            <a-icon type="cloud-upload" :class="$style.synchronize" v-else />
          </div>
          <div :class="$style.content">
            <div :class="$style.left" @click="handleShowFile(index)">
              <!-- <x-oss-image
                :class="$style.thumbnail"
                basePath="/oss/iot/oss"
                v-if="item.type === 'PHOTO'"
                :ossPath="item.path + '?x-oss-process=image/resize,h_50,m_lfit'"
              /> -->
              <oss-image
                :class="$style.thumbnail"
                v-if="item.type === 'PHOTO'"
                :ossPath="item.path + '?x-oss-process=image/resize,h_50,m_lfit'"
              />
              <video-thumbnail
                :class="$style.thumbnail"
                basePath="/oss/iot/oss"
                :ossPath="item.path"
                :hasClick="false"
                v-else-if="item.type === 'VIDEO'"
              />
              <div :class="$style.iconBox" v-else>
                <x-icon :type="FILE_ICON[item.type]" :class="$style.icon" />
              </div>
            </div>
            <div :class="$style.right">
              <div :class="$style.item">
                <span :class="$style.label"
                  >{{ $t('hat.managementModal.file.size') }}:</span
                >
                <span :class="$style.text">{{ item.size + 'mb' }}</span>
              </div>
              <div :class="$style.item">
                <span :class="$style.label"
                  >{{ $t('hat.deviceManagement.details.panel.type') }}:</span
                >
                <span
                  :class="[$style.text, $style.position]"
                  @click="handleShowMap(item)"
                  >{{ `${item.lng}, ${item.lat}` }}</span
                >
              </div>
              <div :class="$style.item" :title="item.mark" v-if="item.mark">
                <span :class="$style.label"
                  >{{ $t('hat.deviceManagement.form.remark') }}:</span
                >
                <span :class="$style.text">{{ item.mark }}</span>
              </div>
              <div :class="$style.item" :title="item.md5" v-else>
                <span :class="$style.label">MD5:</span>
                <span :class="$style.text">{{ item.md5 }}</span>
              </div>
            </div>
          </div>
          <div :class="$style.operation">
            <a-icon
              :class="$style.btn"
              type="delete"
              :title="$t('common.delete')"
              @click="handleDelete(item.fileId)"
              v-if="!checkHasShare($route)"
            />
            <a-icon
              :class="$style.btn"
              type="eye"
              :title="$t('hat.electronFence.preview')"
              v-if="item.cloudStatus === 'SYNC'"
              @click="handleShowFile(index)"
            />
            <a-icon
              :class="$style.btn"
              type="cloud-upload"
              :title="$t('hat.managementModal.file.synchronize')"
              v-if="item.cloudStatus !== 'SYNC'"
              @click="handleSync(item.fileId)"
            />
            <a-icon
              :class="$style.btn"
              type="copy"
              title="MD5"
              @click="copy(item.md5)"
            />
            <a-icon
              :class="$style.btn"
              type="edit"
              :title="$t('hat.deviceManagement.form.remark')"
              v-if="searchForm.listType === 'CLOUD' && !checkHasShare($route)"
              @click="handleRemark(item)"
            />
          </div>
        </div>
      </x-scroll-view>
    </a-spin>
  </div>
</template>

<script>
import {
  Component,
  Vue,
  InjectReactive,
  Inject,
  Prop,
} from 'vue-property-decorator';
import {
  getFileList,
  getUserList,
  getFileStatistics,
  syncFile,
  deleteFile,
  putRemark,
} from '@/services/smart-hat/file';
import Tip from '@/components/tip';
import { createFormModal } from '@triascloud/x-components';
import { clipboardWrite } from '@triascloud/utils';
import EmptyContent from '@/components/empty-content';
import FileRemark from './file-remark.vue';
import moment from 'moment';
import BenzAMRRecorder from 'benz-amr-recorder';
import VideoThumbnail from './video-thumbnail.vue';
import Preview from '@/views/hat/file/preview/index.vue';
import { checkHasShare } from '@/views/hat/screen/utils';
import {
  getFileList as shareData,
  getFileUserList,
} from '@/services/smart-hat/screen-share';
import OssImage from './oss-image.vue';

const DAY = 24 * 60 * 60 * 1000;
@Component({
  components: {
    EmptyContent,
    VideoThumbnail,
    OssImage,
  },
})
export default class File extends Vue {
  @InjectReactive('deviceDetail') deviceDetail;
  @Inject('CHANGE_MINI_MAP') changeMapStatus;
  @Prop({ type: String })
  activeTab;
  @Prop({ type: Object, default: () => {} })
  params;

  checkHasShare = checkHasShare;
  mounted() {
    if (this.params) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.params.hasOwnProperty('startTime')) {
        const { startTime, endTime } = this.params;
        this.timeRange = [
          this.$moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
          this.$moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
        ];
      }
    }
    this.loadFileList();
    this.getMemberList();
  }
  // 文件状态
  CLOUD_STATUS_ENUM = {
    UN_SYNC: this.$t('hat.managementModal.file.unsynchronized'),
    SYNC: this.$t('hat.managementModal.file.synchronized'),
    TO_BE_SYNC: this.$t('hat.managementModal.file.toBeSynchronized'),
  };
  // 排序类型
  SORTER_ENUM = {
    TIME_UP: this.$t('hat.managementModal.file.timeAscending'),
    TIME_DOWN: this.$t('hat.managementModal.file.timeDescending'),
    SIZE_UP: this.$t('hat.managementModal.file.filesizeAscending'),
    SIZE_DOWN: this.$t('hat.managementModal.file.filesizeDescending'),
  };
  // 文件类型
  FILE_TYPE = {
    PHOTO: this.$t('hat.managementModal.file.photo'),
    VIDEO: this.$t('hat.managementModal.file.video'),
    AUDIO: this.$t('hat.managementModal.file.audio'),
    OTHER: this.$t('hat.managementModal.file.other'),
  };
  FILE_ICON = {
    PHOTO: 'tc-color-file-picture',
    VIDEO: 'tc-color-file-video',
    AUDIO: 'tc-color-file-voice',
    OTHER: 'tc-color-file-unknown',
  };
  statusList = ['UN_SYNC', 'SYNC', 'TO_BE_SYNC'];
  sorterList = ['TIME_DOWN', 'TIME_UP', 'SIZE_UP', 'SIZE_DOWN'];
  fileTypeList = ['PHOTO', 'VIDEO', 'AUDIO', 'OTHER'];
  timeRange = [
    // 默认显示一周
    this.$moment(new Date().getTime() - 6 * DAY)
      .startOf('date')
      .format('YYYY-MM-DD HH:mm:ss'),
    this.$moment(new Date().getTime())
      .endOf('date')
      .format('YYYY-MM-DD HH:mm:ss'),
  ];
  tabList = [
    {
      value: 'CLOUD',
      name: this.$t('hat.managementModal.file.cloud'),
    },
    {
      value: 'DEVICE_LOCAL',
      name: this.$t('electricBoxMock.columns.equipemnt'),
    },
  ];
  searchForm = {
    startTime: '',
    endTime: '',
    cloudStatus: undefined,
    type: undefined,
    userId: undefined,
    sorter: 'TIME_DOWN',
    content: '',
    listType: 'CLOUD',
    deviceId: '',
    current: 1,
    size: 10,
  };
  // 预设时间
  get presetTime() {
    return {
      [this.$t('generalView.filter.day')]: [
        moment().startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('screen.last3')]: [
        moment()
          .add(-3, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last7')]: [
        moment()
          .add(-7, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
      [this.$t('matterwebset.screenmanage.last30')]: [
        moment()
          .add(-30, 'd')
          .startOf('date'),
        moment().endOf('date'),
      ],
    };
  }
  disabledDate(current) {
    return current > this.$moment().endOf('date'); //禁用今天之后的日期
  }
  loading = false;
  isEnd = false;
  fileList = [];
  async loadFileList(refresh = true) {
    try {
      this.loading = true;
      this.isEnd = false;
      this.searchForm.deviceId = this.deviceDetail.deviceId;
      if (this.timeRange.length) {
        this.searchForm.startTime = this.$moment(this.timeRange[0]).valueOf();
        this.searchForm.endTime = this.$moment(this.timeRange[1]).valueOf();
      } else {
        this.searchForm.startTime = '';
        this.searchForm.endTime = '';
      }
      if (refresh) {
        this.searchForm.current = 1;
        this.fileList = [];
        // this.getTotalCount();
      }
      const { records } = !checkHasShare(this.$route)
        ? await getFileList(this.searchForm)
        : await shareData({
            ...this.searchForm,
            shareId: this.$route.params.id,
            password: this.$route.params.password,
          });
      this.fileList = [...this.fileList, ...records];
      if (records.length) {
        this.searchForm.current += 1;
      } else {
        this.isEnd = true;
      }
    } finally {
      this.loading = false;
    }
  }
  userList = [];
  async getMemberList() {
    this.userList = !checkHasShare(this.$route)
      ? await getUserList(this.deviceDetail.deviceId)
      : await getFileUserList({
          deviceId: this.deviceDetail.deviceId,
          shareId: this.$route.params.id,
          password: this.$route.params.password,
        });
  }
  totalCount = {
    unSyncCount: {
      number: 0,
      size: 0,
    },
    syncCount: {
      number: 0,
      size: 0,
    },
  };
  async getTotalCount() {
    this.totalCount = await getFileStatistics(this.searchForm);
  }
  activeIndex = 0;
  async handleTabChange(index, type) {
    this.activeIndex = index;
    this.searchForm.listType = type;
    this.loadFileList();
  }
  async handleSearch() {
    this.loadFileList();
  }
  handleReset() {
    this.timeRange = [];
    this.searchForm = {
      startTime: '',
      endTime: '',
      cloudStatus: undefined,
      type: undefined,
      userId: undefined,
      sorter: 'TIME_DOWN',
      content: '',
      listType: this.searchForm.listType,
    };
    this.loadFileList();
  }
  checked = false;
  async handleDelete(fileId) {
    this.checked = false;
    const type = {
      DEVICE_LOCAL: this.$t('hat.managementModal.file.device'),
      CLOUD: this.$t('hat.managementModal.file.cloud'),
    };
    const text = this.$t('hat.managementModal.file.delFile', {
      value: type[this.searchForm.listType],
    });
    const tips = this.$t('hat.managementModal.file.delTip');
    const radioTips = `${this.$t('hat.managementModal.file.radioTips', {
      value:
        type[
          this.searchForm.listType === 'DEVICE_LOCAL' ? 'CLOUD' : 'DEVICE_LOCAL'
        ],
    })}${
      this.searchForm.listType === 'DEVICE_LOCAL'
        ? this.$t('hat.managementModal.file.ifSynchronized')
        : this.$t('hat.managementModal.file.stillExists')
    }`;
    try {
      await createFormModal(
        () => (
          <Tip iconStyle={{ padding: '0 0 22px' }}>
            <template slot="txt">
              <div>{text}</div>
              <ARadio checked={this.checked} onClick={() => this.onChange()}>
                {radioTips}
              </ARadio>
            </template>
            <span slot="subTxt">{tips}</span>
          </Tip>
        ),
        {
          width: '442px',
          title: this.$t('common.tips.prompt'),
          onOk: () => {
            const data = {
              deleteFileType: this.searchForm.listType,
              fileId: fileId,
              syncDelete: this.checked,
            };
            deleteFile(data).then(() => {
              this.$message.success(this.$t('camera.removeSuccessCode'));
              this.loadFileList();
            });
          },
        },
      );
    } catch {
      return false;
    }
  }
  async handleRemark(data) {
    const result = await createFormModal(() => <FileRemark data={data} />, {
      width: 500,
      title: this.$t('hat.managementModal.file.fileRemarks'),
      maskClosable: false,
    });
    if (result) {
      await putRemark(result);
      this.$message.success('修改成功！');
      this.loadFileList();
    }
  }
  handleShowMap(item) {
    let position = [];
    if (item.lng && item.lat) {
      position = [item.lng, item.lat];
    }
    if (position.length) {
      this.changeMapStatus(true, position);
    } else {
      this.changeMapStatus(true);
    }
  }
  onChange() {
    this.checked = !this.checked;
  }
  async handleSync(fileId) {
    await syncFile(fileId);
  }
  async handleShowFile(index) {
    Preview.createModal({
      urls: this.fileList.map(item => item.path),
      opts: this.fileList.map(item => ({
        id: item.fileId,
        relatedFileList: item.relatedFilePathList,
        videoTrackModel: item.videoTrackModel,
        videoTrackModelWatermark: item.videoTrackModelWatermark,
      })),
      paginationFn: this.paginationFn,
      index,
      ossBasePath: '/oss/iot/oss',
    });
  }
  async paginationFn(type) {
    if (type === 'next') {
      await this.loadFileList(false);
      return {
        urls: this.fileList.map(item => item.path),
        opts: this.fileList.map(item => ({
          id: item.fileId,
          relatedFileList: item.relatedFilePathList,
          videoTrackModel: item.videoTrackModel,
          videoTrackModelWatermark: item.videoTrackModelWatermark,
        })),
      };
    }
  }
  amr = null;
  playAudio(url) {
    this.amr = new BenzAMRRecorder();
    this.amr.initWithUrl(url).then(() => {
      this.$message.success(this.$t('hat.managementModal.file.startPlaying'));
      this.amr.play();
    });
    this.amr.onEnded(() => {
      this.$message.success(this.$t('hat.managementModal.file.endOfPlayback'));
    });
  }
  copy(text) {
    clipboardWrite(text).then(() => {
      this.$message.success(`MD5${this.$t('camera.replicatingSuccess')}`);
    });
  }
}
</script>

<style lang="less" module>
.form {
  white-space: nowrap;
  width: 100%;
  position: relative;
  .topSelects {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: center;

    .select {
      width: 105px;
      margin-left: 0;
      margin-right: 8px;
    }
  }
  .tabList {
    display: flex;
    margin-bottom: 10px;
    .tab {
      min-width: 68px;
      padding: 0 2px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      user-select: none;
      border: 1px solid var(--border);
      border-right-color: transparent;
    }
    .tab:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    .tab:last-of-type {
      border-radius: 0 4px 4px 0;
      border-right-color: var(--border);
    }
    .active {
      color: var(--primary);
      border-color: var(--primary) !important;
    }
  }
  .total {
    margin: 10px 0;
  }
  .buttons {
    position: absolute;
    right: 4px;
    top: 0;
    .btn {
      margin-right: 10px;
      min-width: 50px;
      height: 31px;
    }
  }
  .fileList {
    max-height: 375px;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px 20px;
    padding: 5px 10px 5px 4px;
    .file {
      height: 124px;
      padding: 10px 13px 12px 10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      box-shadow: 0px 0px 6px 0px #e1e1e1;
      border-radius: 6px;
      .top {
        display: flex;
        align-items: center;
        .userAvatar {
          height: 24px;
          width: 24px;
          .avatar {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
          .noAvatar {
            display: inline-block;
            color: #fff;
            background-color: var(--x-modal-select-avatar);
            text-align: center;
            line-height: 24px;
            font-size: 12px;
            transform: translateY(0);
          }
        }
        .time {
          margin-left: 10px;
          color: var(--font-info);
        }
        .synchronize {
          margin-left: auto;
          font-size: 16px;
        }
      }
      .content {
        display: flex;
        margin-top: 4px;
        .left {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          .thumbnail {
            height: 66px;
            width: 66px;
            cursor: pointer;
            overflow: hidden;
            border-radius: 8px;
          }
          .iconBox {
            height: 66px;
            width: 66px;
            cursor: pointer;
            border-radius: 8px;
            background: var(--body-bg);
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
              font-size: 60px;
            }
          }
        }
        .right {
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 2px;
            .label {
              display: inline-block;
              width: 40px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .text {
              display: block;
              width: 144px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .position {
              cursor: pointer;
              color: var(--primary);
            }
          }
          .item:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .operation {
        width: 100%;
        height: 34px;
        padding: 0 20px;
        display: none;
        align-items: center;
        background: var(--block-bg);
        box-shadow: 0px -1px 10px 0px #f1f1f1;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 0;
        .btn {
          font-size: 18px;
          color: var(--font-info);
        }
        .btn:hover {
          color: var(--primary);
        }
      }
      &:hover {
        .operation {
          display: flex;
        }
      }
    }
  }
}
</style>
