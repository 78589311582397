import request from '../request';

const serviceName = '/iot-smart-helmet/screen/share';

/** @name 通过相对路径获取图片访问地址 */
export function getAuthShare(data, basePath = 'oss/iot/oss') {
  return request(`${serviceName}/${basePath}/getAuth`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 通过相对路径获取图片访问地址 */
export function getOssDownload(data) {
  return request(`${serviceName}/oss/iot/oss/download`, {
    method: 'POST',
    auth: false,
    body: data,
  });
}

/** @name 创建分享 */
export function shareGenerate(data) {
  return request(`${serviceName}`, {
    method: 'POST',
    body: data,
  });
}

/** @name 判断分享是否有效 */
export function checkShare(data) {
  return request(`${serviceName}/checkShareExpire`, {
    method: 'GET',
    body: data,
    auth: false,
    closeMessage: true,
  });
}

/** @name 获取设备列表 */
export function getDeviceList(data, shareData) {
  return request(`${serviceName}/list`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 获取底部类型数据 */
export function getTypeStatic(data, shareData) {
  return request(`${serviceName}/count`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 获取关键指标 */
export function getTarget(data, shareData) {
  return request(`${serviceName}/target`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 下拉选择编组列表 */
export function getGroupList(data, shareData) {
  return request(`${serviceName}/loadDeviceGroup`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 下拉选择项目列表 */
export function getProjectList(data, shareData) {
  return request(`${serviceName}/pagePm`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/**
 * 在线时长统计
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum
 * @returns
 */
export function onlineDurationStatistics(data, shareData) {
  return request(`${serviceName}/statistics/onSiteBoard`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/**
 * 离场统计
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum
 * @returns
 */
export function departureRanking(data, shareData) {
  return request(`${serviceName}/statistics/offSiteBoard`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/**
 * 时长占比统计
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum 按人/按组
 * @param {'CURRENT_DAY' | 'SEVEN_DAY' | 'THIRTY_DAY'} data.searchDayEnum 时间范围
 * @param {string} data.groupIdList 编组IDS id,id,id
 * @returns
 */
export function durationStatistics(data, shareData) {
  return request(`${serviceName}/statistics/proportion`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/**
 * 设备事件
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum
 * @returns
 */
export function deviceEvent(data, shareData) {
  return request(`${serviceName}/event`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/**
 * 重点关注成员
 * @param {object} data
 * @param {'GROUP' | 'PEOPLE'} data.statisticsPointEnum
 * @returns
 */
export function focusMemberStatistics(data, shareData) {
  return request(`${serviceName}/focusMember`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 可分享的当前在线设备 */
export function getOnlineDevice(data, shareData) {
  return request(`${serviceName}/target/deviceList`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 可分享的实时在场人数明细列表 */
export function shareRealPresenceDetail(data, shareData) {
  return request(`${serviceName}/target/realPresenceDetail`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 可分享的平均在场人数明细列表 */
export function shareRealAvgDetail(data, shareData) {
  return request(`${serviceName}/target/realAvgDetail`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 可分享的设备事件明细列表 */
export function sharePageDetail(data, shareData) {
  return request(`${serviceName}/target/pageDetail`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 可分享的在场-离场明细 */
export function shareEntryOrLeaveDetails(data, shareData) {
  return request(`${serviceName}/entryOrLeaveDetails`, {
    method: 'GET',
    auth: false,
    body: { ...data, ...shareData },
  });
}

/** @name 电子围栏列表 */
export function getGeoFenceZoneList(data) {
  return request(`${serviceName}/geofence/zone/list`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 获取大屏设置数据 */
export function getScreenSetting(data) {
  return request(`${serviceName}/shareScreenSetting`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情 */
export function getDeviceDetail(data) {
  return request(`${serviceName}/deviceDetail`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-设备属性 */
export function getDeviceProperty(data) {
  return request(`${serviceName}/deviceDetail/deviceProperty`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-文件列表 */
export function getFileList(data) {
  return request(`${serviceName}/deviceDetail/deviceFilePage`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-统计文件同步状态 */
export function getFileStatus(data) {
  return request(`${serviceName}/deviceDetail/deviceFileCountCloudStatus`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-文件用户列表 */
export function getFileUserList(data) {
  return request(`${serviceName}/deviceDetail/deviceFileUser`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-事件列表 */
export function getEventList(data) {
  return request(`${serviceName}/deviceDetail/deviceMessagePage`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-事件报表统计 */
export function getMessageCountReport(data) {
  return request(`${serviceName}/deviceDetail/deviceMessageCountReport`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-事件顶部参数 */
export function getEventTopParam(data) {
  return request(`${serviceName}/deviceDetail/deviceMessageTopParam`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-轨迹列表 */
export function getTraceList(data) {
  return request(`${serviceName}/deviceDetail/devicePositionPage`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-轨迹顶部参数 */
export function getTraceTopParam(data) {
  return request(`${serviceName}/deviceDetail/devicePositionTopParam`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-电子围栏列表查询 */
export function getFenceList(data) {
  return request(`${serviceName}/deviceDetail/deviceGeofenceList`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-获取文件标注 */
export function getListMarkByFile(data) {
  return request(`${serviceName}/listMarkByFile`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-获取音频字幕 */
export function getRecognition(data) {
  return request(`${serviceName}/getByFileIdAndUpdateStatus`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

export function getAuthDownload(data, basePath = 'oss/iot/oss') {
  return request(`${serviceName}/${basePath}/download`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-音频文件格式转换 */
export function audioConvert(data, basePath = 'oss/iot/oss') {
  return request(`${serviceName}/${basePath}/convert`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}

/** @name 设备详情-音频文件格式转换状态 */
export function audioConvertStatus(data, taskId, basePath = 'oss/iot/oss') {
  return request(`${serviceName}/${basePath}/status/${taskId}`, {
    method: 'GET',
    auth: false,
    body: data,
  });
}
