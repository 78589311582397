<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { UploadService } from '@triascloud/services';
import { getRecognitionContent } from '@/services/smart-hat/file';
import { funAMap } from '../../electron-fence/components/util';
import { delay } from '@triascloud/utils';
import dayjs from 'dayjs';
import {
  getAuthShare,
  getRecognition,
} from '@/services/smart-hat/screen-share';
import { checkHasShare } from '@/views/hat/screen/utils';
import router from '@/router';

@Component()
export default class PreviewVideo extends Vue {
  @Prop({ type: String, required: true }) url;
  @Prop({ type: String }) fileId;
  @Prop({ type: String }) jsonUrl;
  @Prop({ type: Number }) mark;
  @Prop({ type: Boolean }) watermark;

  async mounted() {
    !checkHasShare(router.app.$route) && (await this.getTrajectoryPath());
    this.calcFn();
    this.initMap();
    this.initChart();
    this.fileId && this.getSubtitles();
  }

  list = [];
  async getTrajectoryPath() {
    if (!this.jsonUrl) return;
    let jsonSrc = '';
    // 判断是否为绝对路径
    if (this.jsonUrl.includes('http')) {
      jsonSrc = this.jsonUrl;
    } else {
      const customService = new UploadService('/oss/iot/oss');
      jsonSrc = await customService.getAuth(this.jsonUrl);
    }
    const res = await (await fetch(jsonSrc)).json();
    const list = res ? res.map(v => JSON.parse(v)) : [];
    this.list = list;
  }

  @Watch('url', { immediate: true })
  async pathChange(path) {
    if (path) {
      this.getAbsolutePath();
    }
  }

  absolutePath = '';
  async getAbsolutePath() {
    // 判断是否为绝对路径
    if (this.url.includes('http')) {
      this.absolutePath = this.url;
    } else {
      // 判断是否为分享页
      if (!checkHasShare(router.app.$route)) {
        const customService = new UploadService('/oss/iot/oss');
        this.absolutePath = await customService.getAuth(this.url);
      } else {
        const data = await getAuthShare({
          url: this.url instanceof Array ? this.url : [this.url],
          shareId: router.app.$route.params.id,
          password: router.app.$route.params.password,
        });
        this.absolutePath = Object.values(data)[0];
      }
    }
  }

  @Watch('subtitles')
  subtitlesChange() {
    let subtitlesContent = this.subtitles;
    this.highlightTextList.forEach(item => {
      const regExp = new RegExp(item, 'g');
      subtitlesContent = subtitlesContent.replace(
        regExp,
        `<span class="audio-mark-text">${item}</span>`,
      );
    });
    document.getElementById('subtitles').innerHTML = subtitlesContent;
  }

  subtitles = '';
  timeUpdate(e) {
    // console.log(e.target.currentTime);
    const currentTime = e.target.currentTime * 1000;
    this.subtitleList.forEach(item => {
      if (currentTime > item.BeginTime && currentTime < item.EndTime) {
        this.subtitles = item.Text;
      }
    });
  }

  // fnDistanceAndAltitude(time) {
  //   console.log(time);
  // }

  arr = [];
  calcFn() {
    const arr = this.list.map((item, index) => {
      return {
        position: [+item.lng, +item.lat],
        duration: this.list[index + 1]
          ? Number(this.list[index + 1].date) - Number(this.list[index].date)
          : 100,
        time: item.date ? dayjs(+item.date).format('YYYY-MM-DD HH:mm:ss') : '',
        speed: item.speed,
        altitude: item.altitude,
      };
    });
    this.arr = arr;
  }
  fnVideoTrajectory() {
    this.marker.moveAlong(this.arr, {
      autoRotation: false,
    });
  }
  AMap = null;
  map = null;
  marker = null;
  lineArr = null;
  async initMap() {
    if (!this.mark) return;
    const AMap = await funAMap({
      plugins: ['AMap.MoveAnimation'],
    });
    this.AMap = AMap;
    this.map = new AMap.Map('TrajectoryWrap', {
      resizeEnable: true,
      // center: [116.397428, 39.90923],
      // zoom: 14,
      // zoomEnable: false,
      // dragEnable: false,
    });

    // this.map.on('zoomchange', () => {
    //   // console.log(e);
    //   console.log(this.map.getZoom());
    // });
    let lineArr = [];
    lineArr = this.list.map(e => {
      return [+e.lng, +e.lat];
    });
    this.lineArr = lineArr;

    const marker = new AMap.Marker({
      map: this.map,
      position: [lineArr[0][0], lineArr[0][1]],
      // offset: new AMap.Pixel(-13, -26),
    });
    this.marker = marker;

    // 绘制轨迹
    const polyline = new AMap.Polyline({
      map: this.map,
      path: lineArr,
      showDir: true,
      strokeColor: '#28F', //线颜色
      // strokeOpacity: 1,     //线透明度
      strokeWeight: 6, //线宽
      // strokeStyle: "solid"  //线样式
    });

    const passedPolyline = new AMap.Polyline({
      map: this.map,
      strokeColor: '#AF5', //线颜色
      strokeWeight: 6, //线宽
    });

    marker.on('moving', function(e) {
      passedPolyline.setPath(e.passedPath);
      // map.setCenter(e.target.getPosition(),true)
    });

    this.map.setFitView([polyline, marker]);
    this.map.setZoom(14.6);
    this.map.setLayers([]);

    this.fnVideoTrajectory();
  }

  myChart = null;
  initChart() {
    if (!this.mark) return;
    const chartDom = document.getElementById('SpeedLabel');
    const myChart = echarts.init(chartDom);
    this.myChart = myChart;
    myChart.setOption({
      series: [
        {
          type: 'gauge',
          center: ['50%', '60%'],
          startAngle: 200,
          endAngle: -20,
          min: 0,
          max: 60,
          splitNumber: 12,
          itemStyle: {
            color: '#FFAB91',
          },
          progress: {
            show: true,
            width: 10,
          },
          pointer: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 10,
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          anchor: {
            show: false,
          },
          title: {
            show: false,
          },
          detail: {
            valueAnimation: true,
            width: '60%',
            lineHeight: 0,
            borderRadius: 2,
            offsetCenter: [0, '15%'],
            fontSize: 16,
            fontWeight: 'bolder',
            formatter: '{value}  km/h',
            color: 'inherit',
          },
          data: [
            {
              value: 20,
            },
          ],
        },
        {
          type: 'gauge',
          center: ['50%', '60%'],
          startAngle: 200,
          endAngle: -20,
          min: 0,
          max: 60,
          itemStyle: {
            color: '#FD7347',
          },
          progress: {
            show: true,
            width: 4,
          },
          pointer: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          detail: {
            show: false,
          },
          data: [
            {
              value: 20,
            },
          ],
        },
      ],
    });

    this.fnSpeed(0, this.arr.length);
  }
  async fnSpeed(current, maxLen) {
    if (current >= maxLen) {
      return;
    }
    const item = this.arr[current];
    this.altitude = item.altitude;
    this.time = item.time;
    if (current > 0) {
      const prev = this.arr[current - 1];
      const num = this.AMap.GeometryUtil.distance(prev.position, item.position);
      this.distance = num + this.distance;
    }
    this.myChart.setOption({
      series: [
        {
          data: [
            {
              value: item.speed,
            },
          ],
        },
        {
          data: [
            {
              value: item.speed,
            },
          ],
        },
      ],
    });
    await delay(item.duration);
    await this.fnSpeed(current + 1, maxLen);
  }

  subtitleList = [];
  highlightTextList = [];
  async getSubtitles() {
    const data = !checkHasShare(router.app.$route)
      ? await getRecognitionContent(this.fileId)
      : await getRecognition({
          fileId: this.fileId,
          shareId: this.$route.params.id,
          password: this.$route.params.password,
        });
    if (data?.caption) {
      const { caption, highlight } = data;
      this.subtitleList = JSON.parse(caption).Result.Sentences;
      this.highlightTextList = JSON.parse(highlight).Words.map(
        item => item.Word,
      );
      this.highlightTextList = Array.from(new Set(this.highlightTextList));
    }
  }

  altitude = 0;
  time = '';
  distance = 0;
  fnDistance(distance = 0) {
    return (distance / 1000).toFixed(2);
  }
  trajectory() {
    return (
      <div class={this.$style.videoPreviewShowWrap}>
        <div class={this.$style.DistanceAndAltitude}>
          <span class={this.$style.dis}>
            <span class={this.$style.label}>距离</span>
            <span>
              <span class={this.$style.num}>
                {this.fnDistance(this.distance)}
              </span>
              <span class={this.$style.unit}>km</span>
            </span>
          </span>
          <span class={this.$style.dis}>
            <span class={this.$style.label}>海拔</span>
            <span>
              <span class={this.$style.num}>{this.altitude}</span>
              <span class={this.$style.unit}>m</span>
            </span>
          </span>
        </div>
        <div class={this.$style.VideoTrajectory}>
          <div class={this.$style.TrajectoryWrap} id="TrajectoryWrap"></div>
          <span>{this.time}</span>
        </div>
        <div class={this.$style.SpeedWrap}>
          <div class={this.$style.SpeedLabel} id="SpeedLabel"></div>
          {this.watermark ? <span>Sandieyun AISCAP Plus</span> : ''}
        </div>
      </div>
    );
  }
  render() {
    return (
      <div class="x-preview-video">
        <div class="video-container">
          <video
            src={this.absolutePath}
            onTimeupdate={this.timeUpdate}
            controls
            autoplay
          ></video>
          {this.jsonUrl && this.mark === 1 && !checkHasShare(router.app.$route)
            ? this.trajectory()
            : ''}
          <div class="subtitles" id="subtitles"></div>
        </div>
      </div>
    );
  }
}
</script>
<style lang="less" scoped>
.video-container {
  width: 100%;
  position: relative;
  text-align: center;

  .subtitles {
    position: absolute;
    left: 50%;
    bottom: 80px;
    transform: translateX(-50%);
    padding: 8px 0;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.8);
    font-size: 22px;
    color: #fff;
    text-align: center;
    text-indent: 8px;
  }
}
</style>
<style lang="less" module>
.videoPreviewShowWrap {
  width: 70%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  .DistanceAndAltitude {
    text-align: left;
    color: #fff;
    display: flex;

    .dis {
      margin-left: 20px;
      margin-top: 20px;
    }

    .dis + .dis {
      margin-left: 40px;
    }

    .label {
      font-size: 20px;
      display: block;
    }

    .num {
      font-size: 36px;
    }

    .unit {
      font-size: 20px;
    }
  }

  .VideoTrajectory {
    position: absolute;
    bottom: 80px;
    left: 20px;
    color: #fff;
    :global {
      .amap-logo {
        display: none !important;
      }
      .amap-container {
        background-color: rgba(255, 255, 255, 0.2) !important;
        background-image: none !important;
        border-radius: 3px;
      }
    }
    .TrajectoryWrap {
      width: 180px;
      height: 120px;
    }
  }

  .SpeedWrap {
    position: absolute;
    bottom: 80px;
    right: 0;
    color: #fff;
    .SpeedLabel {
      width: 200px;
      height: 180px;
      div {
        bottom: -40px;
      }
    }
  }
}
</style>
