<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UploadService } from '@triascloud/services';
import { getAuthShare } from '@/services/smart-hat/screen-share';
import { checkHasShare } from '@/views/hat/screen/utils';
import router from '@/router';

@Component()
export default class OssImage extends Vue {
  @Prop({ type: Array | String, required: true }) ossPath;
  @Prop({ type: String, default: '/oss/iot/oss' }) basePath;

  abPath = null;

  @Watch('ossPath', { immediate: true })
  async handleOssPathChange(path) {
    if (!path) {
      this.abPath = null;
    } else {
      await this.loadOssPath();
    }
  }

  loading = false;
  async loadOssPath() {
    // 判断是否为分享页
    if (this.ossPath.includes('http')) {
      this.abPath = this.ossPath;
    } else {
      if (!checkHasShare(router.app.$route)) {
        const customService = new UploadService(this.basePath);
        this.abPath = await customService.getAuth(this.ossPath);
      } else {
        this.loading = true;
        try {
          const data = await getAuthShare(
            {
              url:
                this.ossPath instanceof Array ? this.ossPath : [this.ossPath],
              shareId: router.app.$route.params.id,
              password: router.app.$route.params.password,
            },
            this.basePath,
          );
          this.abPath = Object.values(data)[0];
        } finally {
          this.loading = false;
        }
      }
    }
  }

  // 分享页的图片
  shareOssImg() {
    if (this.ossPath instanceof Array && this.ossPath.length > 1) {
      return (
        <div>
          {this.abPath.map(item => (
            <img src={item} />
          ))}
        </div>
      );
    } else {
      return (
        <img
          src={this.abPath instanceof Array ? this.abPath[0] : this.abPath}
        />
      );
    }
  }

  // 非分享页的图片
  ossImg() {
    if (this.ossPath instanceof Array && this.ossPath.length > 1) {
      return (
        <div>
          {this.ossPath.map(item => (
            <XOssImage basePath={this.basePath} ossPath={item} />
          ))}
        </div>
      );
    } else {
      return (
        <XOssImage
          basePath={this.basePath}
          ossPath={
            this.ossPath instanceof Array ? this.ossPath[0] : this.ossPath
          }
        />
      );
    }
  }

  render() {
    if (!checkHasShare(router.app.$route)) {
      return this.ossImg();
    } else {
      if (this.abPath) {
        return this.shareOssImg();
      }
      if (this.loading) {
        return (
          <div class="x-oss-image x-oss-image--loading">
            <ASpin />
          </div>
        );
      }
    }
  }
}
</script>
