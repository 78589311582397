<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UploadService } from '@triascloud/services';
import { getAuthShare } from '@/services/smart-hat/screen-share';
import { checkHasShare } from '@/views/hat/screen/utils';
import { Preview } from '@triascloud/x-components';
import router from '@/router';

@Component()
export default class VideoThumbnail extends Vue {
  @Prop({ type: Array | String, required: true }) ossPath;
  @Prop({ type: String, default: '/oss/iot/oss' }) basePath;
  @Prop({ type: String, default: '50' }) size; // 视频截图大小
  @Prop({ type: Boolean, default: true }) hasClick; // 是否显示点击事件

  abPath = null;
  @Watch('ossPath', { immediate: true })
  async handleOssPathChange(path) {
    if (!path) {
      this.abPath = null;
    } else {
      await this.loadOssPath();
    }
  }

  loading = false;
  relativePath = '';
  async loadOssPath() {
    // 获取视频缩略图
    const suffix = `?x-oss-process=video/snapshot,t_1000,f_jpg,w_${this.size},h_${this.size},m_fast`;
    // 判断是否是完整的文件路径
    this.relativePath = this.ossPath.includes('http')
      ? this.ossPath.match(/com\/(\S*)\?/)[1]
      : this.ossPath;
    if (!checkHasShare(router.app.$route)) {
      const customService = new UploadService(this.basePath);
      this.abPath = await customService.getAuth(this.relativePath + suffix);
    } else {
      const data = await getAuthShare({
        url:
          this.ossPath instanceof Array
            ? this.ossPath + suffix
            : [this.ossPath + suffix],
        shareId: router.app.$route.params.id,
        password: router.app.$route.params.password,
      });
      this.abPath = Object.values(data)[0];
    }
  }

  async openVideoModal() {
    let abPath = '';
    if (!checkHasShare(router.app.$route)) {
      const customService = new UploadService(this.basePath);
      abPath = await customService.getAuth(this.relativePath);
    } else {
      const data = await getAuthShare({
        url: this.ossPath instanceof Array ? this.ossPath : [this.ossPath],
        shareId: router.app.$route.params.id,
        password: router.app.$route.params.password,
      });
      abPath = Object.values(data)[0];
    }
    Preview.createModal(abPath);
  }

  videoThumbnail() {
    return (
      <div
        onClick={() => {
          if (this.hasClick) {
            this.openVideoModal();
          }
        }}
        class={this.$style.container}
      >
        <div class={this.$style.play}>
          <AIcon type="caret-right" />
        </div>
        <img class={this.$style.videoThumbnail} src={this.abPath} />
      </div>
    );
  }

  render() {
    return this.videoThumbnail();
  }
}
</script>
<style lang="less" module>
.container {
  position: relative;
  .videoThumbnail {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .play {
    height: 22px;
    width: 22px;
    text-align: center;
    line-height: 22px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
