import request from '../request';

const AttendanceList = '/triascloud-iot-smart-helmet/device/position';

/**
 * @name 运动轨迹点位数据
 */
export function getTrace(body) {
  return request(`${AttendanceList}/v2`, {
    method: 'GET',
    body: body,
    closeMessage: true,
  });
}
export function getDivceProject(deviceId) {
  return request(`${AttendanceList}/topParam/${deviceId}`, {
    method: 'GET',
    closeMessage: true,
  });
}
